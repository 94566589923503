<template>
  <Header title="Owner/Shareholder R.O.I." />

  <Container>
    <h2>Enhancing Owner/Shareholder R.O.I.</h2>
    <p>
      Bryan and Hedden can assist business owners and professionals with the
      design and implementation of creative, tax effective strategies to improve
      the value of their business and personal net worth.
      <br /><br />
      Our creative planning process encompasses all or some of the following
      concepts to enhance the owners' personal financial position.
      <br /><br />
      In this process we would typically review the opportunities that exist in
      the following areas:
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax deferred individual compensation programs</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Custom designed owner/manager benefit plans</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Individual pension plans for significant shareholders</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Unlimited supplementary pension plans</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Forward averaging of bonuses</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Hidden financing opportunities</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Living buyout strategies</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax deferred buyout arrangements</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Enhanced share redemption strategies</p></li>
    </ul>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
